import { range, flatten } from "lodash";

const zoneGroup = [
  {
    label: "Lower Zone",
    numbers: [
      ...range(4, 28), // generate 4 - 27
    ],
  },
];

const otherZones = [];

const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});

export default Object.freeze({
  ZONES: flatten([...zones, ...otherZones]),
});
