import { range, flatten } from "lodash";

const zoneGroup = [
  {
    label: "Floor",
    numbers: [
      ...["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "A9", "A10", "A11", "A12"],
      ...["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "B9"]
    ],
  },
  {
    label: "Lower Zone",
    numbers: [
      ...range(201, 205), // generate 201 - 204
      ...["206", "207"],
      ...range(209, 220), // generate 209 - 219
      ...["221", "222"],
      ...range(224, 232), // generate 224 - 231
      ...["233", "234"],
      ...range(236, 247), // generate 236 - 246
      ...["248", "249"],
      ...range(251, 255), // generate 251 - 254
    ],
  },
  {
    label: "Club Seats",
    numbers: range(336, 347), // generate 336 - 346
  },
];

const otherZones = [
  {
    label: "General Admission",
  }
];

const zones = zoneGroup.map((zone) => {
  return zone.numbers.map((number) => ({ label: `${zone.label} ${number}` }));
});

export default Object.freeze({
  ZONES: flatten([...zones, ...otherZones]),
});
