import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { generateRows, WCRRow } from "../constants/WorldJuniorSeatRows";

class WorldJuniorRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  getRows() {
    const { section } = this.props;
    const sectionNo = section.split(" ").pop();

    if (["4", "8", "9"].includes(sectionNo)) {
      return generateRows("A", "K");
    } else if (["5", "7"].includes(sectionNo)) {
      return [...generateRows("A", "L"), ...WCRRow];
    } else if (["6", "10", "11"].includes(sectionNo)) {
      return [...generateRows("A", "J"), ...WCRRow];
    } else if (["12", "27"].includes(sectionNo)) {
      return [...generateRows("A", "S"), ...generateRows("AA", "OO")];
    } else if (["13", "17"].includes(sectionNo)) {
      return [...generateRows("A", "R"), ...WCRRow, ...generateRows("AA", "QQ")];
    } else if (["14", "15", "16", "22", "23", "24", "25", "26"].includes(sectionNo)) {
      return [...generateRows("A", "S"), ...generateRows("AA", "QQ")];
    } else if (["18", "21"].includes(sectionNo)) {
      return [...generateRows("A", "Q"), ...generateRows("AA", "QQ")];
    } else if (["19", "20"].includes(sectionNo)) {
      return [...generateRows("A", "S"), ...generateRows("BB", "QQ")];
    } else {
      return [];
    }
  };

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section && this.getRows().map((row, index) => (
            <MenuItem selected={row.value === this.props.value} value={row.value} key={index}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

WorldJuniorRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default WorldJuniorRowSelect;
