import React, { Component } from "react";
import moment from "moment-timezone";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Checkbox,
  Grid,
} from "@material-ui/core";
import Context from "../context";
import TEAMS from "../constants/Teams";

const styles = (theme) => ({
  FormControl: {
    marginTop: "1rem",
  },
  teamsList: {
    marginBottom: "1.5rem",
  },
  teamItemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  teamLogoWrapper: {
    marginBottom: "1.5rem",
    cursor: "pointer",
  },
  teamLogo: {
    height: 75,
    width: 75,
  },
});

class SelectGamesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      games: null,
      isLoadingGames: false,
    };
  }

  renderLogos(context) {
    const { selectedTeam } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography
          variant="body2"
          color="textPrimary"
          style={{
            fontWeight: "bold",
            marginBottom: "2rem",
            marginTop: "2rem",
          }}
        >
          Select Team
        </Typography>
        <Grid container spacing={32} className={classes.teamsList}>
          {TEAMS.map(team => (
            <Grid
              key={team.homeTeamSlug}
              item
              xs={12}
              md={2}
              className={classes.teamItemWrapper}
              onClick={() => {
                this.setState({
                  selectedTeam: team.homeTeamSlug,
                  isLoadingGames: true,
                });
                context.onTeamSelection(team.homeTeamSlug);
              }}
              style={
                selectedTeam !== "" && selectedTeam !== team.homeTeamSlug
                  ? { opacity: 0.5, transition: ".25s all ease-in-out" }
                  : { opacity: 1, transition: ".25s all ease-in-out" }
              }
            >
              <div className={classes.teamLogoWrapper}>
                <img
                  className={classes.teamLogo}
                  src={team.logo}
                  alt={team.fullName}
                />
              </div>
              <Typography
                variant="title"
                style={{
                  cursor: "pointer",
                }}
              >
                {team.fullName}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  }

  renderGames(context) {
    const { state, games, isSpecialSelected, isLoadingGames, selectAllGames } = context;
    if (this.state.isLoadingGames && isLoadingGames) {
      return (
        <Typography variant="body2">Loading games... Please wait...</Typography>
      );
    } else if (games && games.length === 0) {
      return (
        <Typography variant="body2">No games available</Typography>
      );
    }

    return (
      games && (
        <List>
          <ListItem button onClick={selectAllGames} disabled={state.selectedTeam === 'taylor-swift'}>
            <ListItemText
              primary="Select All Games"
              secondary={
                state.isAllGamesSelected ? "All Games Selected" : ""
              }
            />
          </ListItem>
          {games.map((game) => {
            const regularGameDate = `${moment
              .tz(game.date, game.timezone)
              .format("ddd, MMM D YYYY")} at ${moment
                .tz(game.date, game.timezone)
                .format("h:mm A")}`;
            const playoffsGameDate = `${moment
              .tz(game.date, game.timezone)
              .format("ddd, MMM D YYYY")}`;
            const gameDate =
              game.isPlayoffs && !game.showOpponent
                ? playoffsGameDate
                : regularGameDate;
            // check if game.id is in context.state.selectedGames
            const isConcertSelected = state.selectedGames.length > 0 && state.selectedGames.indexOf(game.id) === -1 && state.selectedTeam === 'taylor-swift';

            return (
              <ListItem
                key={game.id}
                button
                onClick={context.handleToggle(game)}
                disabled={(!game.isSpecial && isSpecialSelected) || isConcertSelected}
              >
                <ListItemText primary={game.longName} secondary={gameDate} />
                <ListItemSecondaryAction>
                  <Checkbox
                    onChange={context.handleToggle(game)}
                    checked={state.selectedGames.indexOf(game.id) >= 0}
                    disabled={(!game.isSpecial && isSpecialSelected) || isConcertSelected}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )
    );
  }

  render() {
    return (
      <Context.Consumer>
        {(context) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="headline">1. Select Games</Typography>
              {this.renderLogos(context)}
              {this.renderGames(context)}
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default withStyles(styles)(SelectGamesForm);
