export const generateRows = (start = 'A', end = 'Z') => {
  const letters = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    'AA', 'BB', 'CC', 'DD', 'EE', 'FF', 'GG', 'HH', 'II', 'JJ', 'KK', 'LL', 'MM',
    'NN', 'OO', 'PP', 'QQ', 'RR', 'SS', 'TT', 'UU', 'VV', 'WW', 'XX', 'YY', 'ZZ'
  ];
  const startIndex = letters.indexOf(start);
  const endIndex = letters.indexOf(end);
  const rows = letters.filter((letter, index) => index >= startIndex && index <= endIndex);
  return rows.map(row => {
    return {
      value: row,
      label: `Row ${row}`
    }
  });
}

export const WCRRow = [{
  value: 'WCR',
  label: 'Row WCR'
}];

export default {
  generateRows,
  WCR_ROW: WCRRow
};
